<template>
    <div class="flex flex-1 flex-col">
        <group-layout v-if="isGroup">
            <slot>
                <prime-skeleton v-if="isLoading" />
                <slot v-else />
            </slot>
        </group-layout>

        <storefront-or-kiosk-layout-wrapper
            v-else
            :query="data"
        >
            <slot>
                <prime-skeleton v-if="isLoading" />
                <slot v-else />
            </slot>
        </storefront-or-kiosk-layout-wrapper>

        <prime-dialog
            v-model:visible="showWelcome"
            :header="welcomeTitle"
            modal
            :dismissable-mask="true"
            :style="{ width: '50vw' }"
            :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
        >
            <p
                class="m-0"
                v-html="welcomeText"
            />
        </prime-dialog>
    </div>
</template>

<script setup lang="ts">
import { useSetViewer } from '~/composables/use-login'
import {
    type ActiveFacility,
    ActiveFacilityFragment,
    provideActiveFacility
} from '~/composables/use-active-facility'
import { computed, onBeforeUnmount, ref } from 'vue'
import { useRpQuery } from '~/composables/graphql'
import { useQuery } from '@tanstack/vue-query'
import { getFragmentData, graphql } from '~/resources/graphql'
import { createError, useRoute, useState } from '#app'
import PrimeDialog from 'primevue/dialog'
import { useLayoutMode } from '~/composables/use-layout-mode'
import { useHead } from 'unhead'
import StorefrontOrKioskLayoutWrapper from '~/components/Layouts/StorefrontLayout/StorefrontOrKioskLayoutWrapper.vue'
import PrimeSkeleton from 'primevue/skeleton'
import GroupLayout from '~/components/Layouts/GroupLayout/GroupLayout.vue'

const { isGroup } = useLayoutMode()
const facilitySlug = useState<string>('facilityParamSlug')
const storefrontBaseSlug = useState('storefrontParamSlug')
const storefrontSlug = computed(() => {
    return typeof storefrontBaseSlug.value === 'string' &&
        storefrontBaseSlug.value.startsWith('kiosk-')
        ? storefrontBaseSlug.value.substring(6)
        : storefrontBaseSlug.value
})

const setViewer = useSetViewer()
const query = useRpQuery({ orgLevel: true })
const { data, isLoading, suspense } = useQuery({
    queryKey: ['DefaultLayoutQuery', facilitySlug, storefrontSlug, isGroup],
    placeholderData: previousData => previousData,
    queryFn: () => {
        return query(
            graphql(/* GraphQL */ `
                query DefaultLayoutStorefrontOrKioskQuery(
                    $storefrontSlug: String!
                    $facilitySlug: String!
                ) {
                    ...StorefrontOrKioskWrapperQueryFragment
                    viewer {
                        ...ViewerFragment
                    }
                    facility(slug: $facilitySlug) {
                        ...ActiveFacilityFragment
                    }
                    organization {
                        welcomeText
                        welcomeTitle
                    }
                }
            `),
            {
                facilitySlug: facilitySlug.value,
                storefrontSlug: !isGroup.value ? storefrontSlug.value : ''
            }
        )
    }
})

const response = await suspense()
if (data.value !== undefined) {
    if (data.value.viewer !== null) {
        setViewer(data.value.viewer)
    } else {
        setViewer(undefined)
    }
}

const activeFacility = computed<ActiveFacility>(() => {
    const result = data.value?.facility
    if (result === undefined || result === null) {
        throw createError({
            statusCode: 404,
            statusMessage: 'Page Not Found'
        })
    }

    return getFragmentData(ActiveFacilityFragment, result)
})
provideActiveFacility(activeFacility)

const route = useRoute()
const showWelcome = ref(route.query.action === 'welcome')

const welcomeTitle = computed(() => data.value?.organization.welcomeTitle ?? '')
const welcomeText = computed(() => data.value?.organization.welcomeText ?? '')

const unmount = ref(false)
const headStyle = computed(() => {
    if (unmount.value) {
        return []
    }

    return activeFacility.value.branding.styleString
})

useHead({
    style: headStyle
})

onBeforeUnmount(() => {
    // Clear styles when leaving the component
    unmount.value = true
})
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
