<template>
    <kiosk-layout
        v-if="isKiosk"
        :storefront-prefix="storefrontPrefix"
        :catalog="catalog"
        :profile-allowed="storefront.profileAllowed"
        :idle-timeout="storefront.idleTimeout"
        :idle-timeout-message="storefront.idleTimeoutMessage"
    >
        <slot>
            <slot />
        </slot>
    </kiosk-layout>

    <storefront-layout
        v-else
        :storefront-prefix="storefrontPrefix"
        :catalog="catalog"
    >
        <slot>
            <slot />
        </slot>
    </storefront-layout>
</template>

<script lang="ts">
import { graphql } from '~/resources/graphql'

export const StorefrontOrKioskWrapperQueryFragment = graphql(/* GraphQL */ `
    fragment StorefrontOrKioskWrapperQueryFragment on Query {
        facility(slug: $facilitySlug) {
            storefront(slug: $storefrontSlug) {
                ...UseStorefrontStorefrontFragment
            }
        }
    }
`)
</script>

<script setup lang="ts">
import KioskLayout from '~/components/Layouts/StorefrontLayout/KioskLayout.vue'
import StorefrontLayout from '~/components/Layouts/StorefrontLayout/StorefrontLayout.vue'
import { useLayoutMode } from '~/composables/use-layout-mode'
import { createError, useState } from '#app'
import { computed, provide } from 'vue'
import { createStorefront } from '~/composables/use-storefront'
import { useActiveFacility } from '~/composables/use-active-facility'
import { type FragmentType, getFragmentData } from '~/resources/graphql'

const props = defineProps<{
    query: FragmentType<typeof StorefrontOrKioskWrapperQueryFragment>
}>()

const queryData = computed(() =>
    getFragmentData(StorefrontOrKioskWrapperQueryFragment, props.query)
)

const { isKiosk } = useLayoutMode()
const activeFacility = useActiveFacility()

const storefrontBaseSlug = useState('storefrontParamSlug')
const storefrontSlug = computed(() => {
    return typeof storefrontBaseSlug.value === 'string' &&
        storefrontBaseSlug.value.startsWith('kiosk-')
        ? storefrontBaseSlug.value.substring(6)
        : storefrontBaseSlug.value
})

const storefrontPrefix = computed(() => {
    if (storefrontSlug.value !== '') {
        return `/${activeFacility.value.slug}/${storefrontSlug.value}`
    } else {
        return `/${activeFacility.value.slug}`
    }
})

const storefrontFragment = computed(() => {
    if (
        queryData.value.facility === null ||
        queryData.value.facility.storefront === undefined ||
        queryData.value.facility.storefront === null
    ) {
        throw createError({
            statusCode: 404,
            statusMessage: 'Page Not Found'
        })
    }

    return queryData.value.facility.storefront
})

const storefront = createStorefront(storefrontFragment)

const catalog = computed(() => {
    return storefront.value.config
})
provide('CATALOG', catalog)
provide('STOREFRONT_PREFIX', storefrontPrefix)
</script>
