<template>
    <app-base>
        <header class="border-b">
            <nuxt-link
                class="text-button flex h-10 items-center justify-center bg-primary-500 py-2 text-lg font-medium uppercase hover:underline"
                :to="activeFacility.organization.topBarLink"
            >
                {{ activeFacility.organization.topBarText }}
            </nuxt-link>

            <div
                class="relative flex flex-col justify-center overflow-hidden bg-white"
            >
                <div>
                    <div
                        class="relative mx-0 flex items-center justify-between gap-4 px-4 py-6 md:px-7 lg:static lg:px-16 lg:py-6"
                    >
                        <div class="flex items-center gap-8">
                            <organization-facility-logo />

                            <a
                                :href="
                                    preserveModeLink(`/${activeFacility.slug}`)
                                "
                            >
                                <prime-button
                                    type="button"
                                    label="Required Documents"
                                />
                            </a>

                            <div class="font-semibold text-gray-600">
                                {{ activeFacility.shortName }}
                            </div>
                        </div>

                        <div
                            class="flex w-full flex-1 items-center justify-end"
                        >
                            <ul class="flex items-center">
                                <contact-us-button
                                    v-if="
                                        activeFacility.organization
                                            .contactUsLinkText !== null
                                    "
                                    :link-text="
                                        activeFacility.organization
                                            .contactUsLinkText
                                    "
                                    :facility-id="activeFacility.id"
                                />

                                <profile-buttons />
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <div class="flex flex-1 flex-col">
            <div
                class="z-2 mx-0 my-2 flex-1 px-4 py-4 md:mx-6 md:my-6 lg:mx-8 lg:px-8"
            >
                <div
                    v-if="completedContract !== undefined"
                    class="mx-auto mb-4 flex w-full max-w-screen-xl whitespace-pre-wrap border-4 bg-gray-100 p-6 text-lg font-medium"
                >
                    You have successfully submitted {{ completedContract }}
                </div>

                <div class="mx-auto flex max-w-screen-xl items-start gap-8">
                    <div class="w-full">
                        <slot />
                    </div>
                </div>
            </div>

            <powered-by-footer />
        </div>
    </app-base>
</template>

<script setup lang="ts">
import AppBase from '~/components/Layouts/AppBase.vue'
import PoweredByFooter from '~/components/PoweredByFooter.vue'
import ProfileButtons from '~/components/Layouts/ProfileButtons.vue'
import { useActiveFacility, useOrgShortTitle } from '#imports'
import ContactUsButton from '~/components/Layouts/ContactUsButton.vue'
import NuxtLink from '#app/components/nuxt-link'
import { createError, useRoute, useState } from '#app'
import { computed, provide, ref } from 'vue'
import PrimeButton from 'primevue/button'
import { useLayoutMode } from '#imports'
import OrganizationFacilityLogo from '~/components/Layouts/OrganizationFacilityLogo.vue'
import { useQuery } from '@tanstack/vue-query'
import { graphql } from '~/resources/graphql'
import { provideStorefront } from '~/composables/use-storefront'
import { useRpQuery } from '~/composables/graphql'
import { useHead } from 'unhead'

const activeFacility = useActiveFacility()
const groupBookingId = useState<string>('groupBookingId')
const activeFacilityId = computed(() => {
    return activeFacility.value.id
})

const query = useRpQuery()
const { data, suspense } = useQuery({
    queryKey: ['DefaultLayoutGroupQuery', activeFacilityId, groupBookingId],
    placeholderData: previousData => previousData,
    queryFn: () => {
        return query(
            graphql(/* GraphQL */ `
                query DefaultLayoutGroupQuery(
                    $input: SimpleSpokeInput!
                    $groupBookingId: ID!
                ) {
                    groupEvent(id: $groupBookingId) {
                        title
                        ...GroupManagementStorefrontPlanContractFragment
                    }
                }
            `),
            {
                input: {
                    facilityId: activeFacility.value.id
                },
                groupBookingId: groupBookingId.value
            }
        )
    }
})

const groupBookingFragment = computed(() => {
    if (data.value === undefined) {
        return null
    }

    if (data.value.groupEvent === null) {
        throw createError({
            statusCode: 404,
            statusMessage: 'Page Not Found'
        })
    }

    return data.value.groupEvent
})

const route = useRoute()
const completedContract = computed(() => route.query.completedContract)

const { preserveModeLink } = useLayoutMode()
await suspense()

const shortTitle = useOrgShortTitle()
useHead({
    title: () => shortTitle.value + ' | ' + groupBookingFragment.value?.title
})

provide('GROUP_CONTRACT', groupBookingFragment)
provide('STOREFRONT_PREFIX', 'group')
provideStorefront(
    ref({
        treeNavigation: false,
        collectMoney: true,
        profileAllowed: true,
        allowGuestCheckout: false,
        idleTimeout: 180,
        idleTimeoutMessage: '',
        config: {}
    })
)
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
